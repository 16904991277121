import { EScreenSize } from '@/libs/utils/screensize';
import { useScreenSize } from '@/libs/utils/useScreensize';
import { useEffect, useRef, useState } from 'react';
const RENDER_FB_BUTTON = 'RENDER_FB_BUTTON';
export const FacebookAuthButton = () => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [isButtonLoad, setIsButtonLoad] = useState<boolean>(false);
  const authButtonUrl = process.env.FACEBOOK_AUTH_BUTTON_URL;
  const screenSize = useScreenSize();
  useEffect(() => {
    if (ref?.current && isButtonLoad) {
      ref.current.contentWindow?.postMessage({
        type: RENDER_FB_BUTTON,
        payload: {
          innerWidth: window.innerWidth
        }
      }, authButtonUrl);
    }
  }, [authButtonUrl, isButtonLoad]);
  return <iframe ref={ref} onLoad={() => setIsButtonLoad(true)} src={authButtonUrl} title='facebook button iframe' id='facebook-button-iframe' height='40px' width={screenSize === EScreenSize.xxs ? '360px' : '400px'} data-sentry-component="FacebookAuthButton" data-sentry-source-file="index.tsx" />;
};
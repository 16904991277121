import { EScreenSize } from '@/libs/utils/screensize';
import { useScreenSize } from '@/libs/utils/useScreensize';
import { useEffect, useRef, useState } from 'react';
const RENDER_GOOGLE_BUTTON = 'RENDER_GOOGLE_BUTTON';
export const GoogleAuthButton = () => {
  const googleIframeRef = useRef<HTMLIFrameElement>(null);
  const [isGoogleButtonLoad, setIsGoogleButtonLoad] = useState<boolean>(false);
  const googleAuthButtonUrl = process.env.GOOGLE_AUTH_BUTTON_URL;
  const screenSize = useScreenSize();
  const postMessageToButton = ({
    type,
    target,
    origin,
    payload = {}
  }: {
    type: string;
    target: HTMLIFrameElement;
    origin: string;
    payload?: {
      googleAppId?: string;
      innerWidth?: number;
    };
  }) => {
    target?.contentWindow?.postMessage({
      type,
      payload
    }, origin);
  };
  useEffect(() => {
    if (googleIframeRef?.current && isGoogleButtonLoad) {
      postMessageToButton({
        type: RENDER_GOOGLE_BUTTON,
        target: googleIframeRef.current,
        origin: googleAuthButtonUrl,
        payload: {
          innerWidth: window.innerWidth,
          googleAppId: process.env.DEFAULT_GOOGLE_APP_ID
        }
      });
    }
  }, [googleAuthButtonUrl, isGoogleButtonLoad]);
  return <iframe ref={googleIframeRef} onLoad={() => setIsGoogleButtonLoad(true)} src={googleAuthButtonUrl} title='google button iframe' id='google-button-iframe' height='40px' width={screenSize === EScreenSize.xxs ? '360px' : '400px'} data-sentry-component="GoogleAuthButton" data-sentry-source-file="index.tsx" />;
};
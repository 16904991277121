'use client';

import styles from './form.styles.module.css';
import { authUserByEmail, authUserViaFacebook, authUserViaGoogle } from '@/libs/api/login';
import { useEffect, useRef, useState } from 'react';
import { CaptchaIframe, RECAPTCHA_ACTIONS, useCaptcha } from '@/features/recaptcha';
import { UserApiErrorV1 } from '@/libs/api/eagle-api-wrapper/api-gateway';
import { RECAPTCHA_MODES } from '../recaptcha/hooks/useCaptcha';
import { SignInUpEmailForm } from './SignInUpEmailForm';
import { SignInPasswordForm } from './SignInPasswordForm';
import { ResponseError } from '@/root/types/ResponseError';
import { GoogleLoginDTO, FacebookLoginDTO } from '@/libs/api/login';
import { AuthType } from '@/libs/api/auth';
import { AuthResponsePayloadDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/auth-response.dto';
import { logger } from '@/libs/utils/logger';
enum EAuthSteps {
  SIGN_IN_UP_EMAIL = 'SIGN_IN_UP_EMAIL',
  SIGN_UP_PASSWORD = 'SIGN_UP_PASSWORD',
  SIGN_IN_PASSWORD = 'SIGN_IN_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  EMAIL_SENT_FORM = 'EMAIL_SENT_FORM',
}
export type TAuthFormAction = (formData: FormData) => Promise<void> | void;
type TEntryFormProps = {
  captchaData: ReturnType<typeof useCaptcha>;
  onLoginSuccess: () => void;
  domain: string;
};
export enum AuthProvider {
  facebook = 'Facebook',
  google = 'Google',
  arkadium = 'Arkadium',
}
enum Events {
  FacebookAuth = 'update-user-info',
  GoogleAuth = 'auth-google-user',
}
type TAuthProviderAppId = {
  authProviderAppId: string;
};
export interface OAuthUserResponse {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  access_token: string;
}
export type TAuthorizedData = {
  user: OAuthUserResponse;
  eventType: AuthProvider;
};
type SocialLoginInfoType = {
  data: GoogleLoginDTO | (FacebookLoginDTO & TAuthProviderAppId);
  eventType: AuthProvider;
};
export const EntryForm = ({
  captchaData,
  onLoginSuccess,
  domain
}: TEntryFormProps) => {
  const [errors, setErrors] = useState([] as string[]);
  const [errorCode, setErrorCode] = useState<UserApiErrorV1 | null>(null);
  const [currentStep, setCurrentStep] = useState<EAuthSteps | null>(EAuthSteps.SIGN_IN_UP_EMAIL);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [captchaMode, setCaptchaMode] = useState<RECAPTCHA_MODES | undefined>(undefined);
  const fbIframeRef = useRef();
  const [isFbButtonLoad, setIsFbButtonLoad] = useState<boolean>(false);
  const [eventType, setEventType] = useState<AuthProvider>(AuthProvider.arkadium);
  const [socialUser, setSocialUser] = useState<OAuthUserResponse>();
  const [authType, setAuthType] = useState<AuthType>(AuthType.EmailPassword);
  const domainWithProtocol = domain.startsWith('http') ? domain : 'https://' + domain;
  const {
    captchaToken,
    captchaTokenError,
    clearCaptchaData,
    getCaptchaToken,
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    showChallengeRecaptcha,
    setShowChallengeRecaptcha
  } = captchaData;
  useEffect(() => {
    if (captchaTokenError) {
      setErrors(errors => [...errors, captchaTokenError]);
    }
  }, [captchaTokenError]);
  useEffect(() => {
    const isChallengeMode = errorCode === UserApiErrorV1.CaptchaScoreIsTooLow;
    if (!isChallengeMode) {
      clearCaptchaData();
    }
    if (errorCode === UserApiErrorV1.UserNotConfirmedError || errorCode === UserApiErrorV1.UserEmailConfirmationCodeIsInvalid) {
      //TODO: handle user confirmation
    }
    setShowChallengeRecaptcha(isChallengeMode);
    if (isChallengeMode) {
      setCaptchaMode(RECAPTCHA_MODES.CHALLENGE);
    }
  }, [errorCode, clearCaptchaData, setShowChallengeRecaptcha]);
  useEffect(() => {
    if (captchaToken) {
      (async () => {
        const {
          request,
          successCallback,
          errorCallback
        } = getRequestMethods();
        const res = await request?.();
        if (res && 'errorCode' in res) {
          errorCallback?.(res);
          return;
        }
        successCallback?.();
        clearCaptchaData();
      })();
    }
  }, [captchaToken]);
  const signInRequest = {
    request: () => authUserByEmail({
      email,
      password,
      captchaToken,
      registrationPlaceUrl: new URL(domainWithProtocol),
      ...(captchaMode ? {
        captchaMode
      } : {})
    }),
    successCallback: onLoginSuccess,
    errorCallback: (res: ResponseError) => {
      setErrors(res.message as string[]);
      setErrorCode(res.errorCode);
    }
  };
  const getRequestMethods = () => {
    const emptyRequest = {
      request: null,
      successCallback: null,
      errorCallback: null
    };
    switch (currentStep) {
      case EAuthSteps.SIGN_IN_PASSWORD:
        return signInRequest;
      case EAuthSteps.SIGN_UP_PASSWORD:
        return emptyRequest;
      //TODO: describe the actual requests
      default:
        return emptyRequest;
    }
  };
  const performOAuthLogin = async (loginData: GoogleLoginDTO | FacebookLoginDTO, loginEventType: AuthProvider) => {
    if (loginEventType === AuthProvider.google) {
      return authUserViaGoogle(loginData as GoogleLoginDTO);
    }
    return authUserViaFacebook(loginData as FacebookLoginDTO);
  };
  const onAuthorized = async (data: TAuthorizedData) => {
    if (!data.user) {
      return;
    }

    // login via fb/google with eagle
    if ('id' in data.user) {
      const isGoogle = data.eventType === AuthProvider.google;
      const loginData: (GoogleLoginDTO | FacebookLoginDTO) & TAuthProviderAppId = {
        token: data.user.access_token,
        email: data.user.email ?? null,
        emailRetrievalConsent: false,
        registrationPlaceUrl: domainWithProtocol,
        authProviderAppId: isGoogle ? process.env.DEFAULT_GOOGLE_APP_ID as string : '',
        //TODO: switch to dynamic facebookAppId from arena config
        captchaToken,
        ...(captchaMode ? {
          captchaMode
        } : {})
      };
      try {
        const result = await performOAuthLogin(loginData, data.eventType);
        if (result && 'errorCode' in result) {
          setErrorCode(result.errorCode);
          setErrors(result.message as string[]);
        }
        handleSuccessOAuthLogin((result as AuthResponsePayloadDto).isRegistered, data);
        clearCaptchaData();
      } catch (err: unknown | ResponseError) {
        if ((err as ResponseError).errorCode !== 1023) {
          clearCaptchaData();
        }
        if ((err as ResponseError).errorCode === 1023) {
          setCaptchaMode(RECAPTCHA_MODES.CHALLENGE);
          setShowChallengeRecaptcha(true);
        }

        // EmailDoesNotSpecified
        if ((err as ResponseError).errorCode === 1007) {
          // openRequestEmailForm();
          logger.debug('openRequestEmailForm', err);
          const authenticationType = data.eventType === AuthProvider.google ? AuthType.Google : AuthType.Facebook;
          setAuthType(authenticationType);
        }

        // UserNotConfirmedError
        if ((err as ResponseError).errorCode === 1018) {
          setEmail(email);
          logger.debug('userNotConfirmed', err);
          // dispatch(setLoginStep(ELoginSteps.AFTER_SIGN));
          setCurrentStep(EAuthSteps.EMAIL_SENT_FORM);
          // dispatch(setLoginPrevStep(ELoginSteps.REQUEST_EMAIL));
        }
      }
    }
  };
  const handleSuccessOAuthLogin = (isUserRegistered: boolean, authorizedData: TAuthorizedData | SocialLoginInfoType) => {
    const authEventType = 'eventType' in authorizedData ? authorizedData.eventType : undefined;
    if (isUserRegistered) {
      // AnalyticsGeneral.registration(caller, authEventType);
      logger.debug('user registered');
    } else {
      logger.debug('user logged in');
      onLoginSuccess();
    }

    // AnalyticsGeneral.login(ActionTypes.LOGIN, caller, authEventType);
  };
  useEffect(() => {
    if (captchaToken && currentStep === EAuthSteps.SIGN_IN_UP_EMAIL && (eventType === AuthProvider.facebook || eventType === AuthProvider.google)) {
      void onAuthorized({
        eventType,
        user: socialUser as OAuthUserResponse
      });
    }
  }, [captchaToken, currentStep, eventType, onAuthorized, socialUser]);
  useEffect(() => {
    window.addEventListener('message', onLoginMessage);
    return () => window.removeEventListener('message', onLoginMessage);
  }, []);
  const onLoginMessage = (message: MessageEvent) => {
    const event = message.data;
    if (!event) {
      return;
    }
    switch (event.action) {
      case Events.FacebookAuth:
        setSocialUser(event.data);
        setEventType(AuthProvider.facebook);
        getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
        break;
      case Events.GoogleAuth:
        setSocialUser(event.user);
        setEventType(AuthProvider.google);
        getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
        break;
      default:
        break;
    }
  };
  const signInUpEmailFormAction = (formData: FormData) => {
    setErrors([]);
    const data = Object.fromEntries(formData);
    const email = data.email as string;
    setEmail(email);
    //TODO: make request to Eagle to identify if we should proceed with sign up or sign in

    //login flow as an example
    setCurrentStep(EAuthSteps.SIGN_IN_PASSWORD);
    return;
  };
  const signInPasswordFormAction = (formData: FormData) => {
    setErrors([]);
    const data = Object.fromEntries(formData);
    const password = data.password as string;
    setPassword(password);
    getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN); //crucial step to trigger recaptcha token which triggers the eagle request
  };
  const renderForm = () => {
    switch (currentStep) {
      case EAuthSteps.SIGN_IN_UP_EMAIL:
        return <SignInUpEmailForm action={signInUpEmailFormAction} errors={errors} />;
      case EAuthSteps.SIGN_UP_PASSWORD:
        //TODO: add sign up password form
        return null;
      case EAuthSteps.SIGN_IN_PASSWORD:
        return <SignInPasswordForm action={signInPasswordFormAction} email={email} errors={errors} />;
      case EAuthSteps.FORGOT_PASSWORD:
        return null;
      case EAuthSteps.EMAIL_SENT_FORM:
        return null;
      default:
        return null;
    }
  };
  const renderCaptcha = () => {
    return <CaptchaIframe isVisible={showChallengeRecaptcha} iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded} data-sentry-element="CaptchaIframe" data-sentry-component="renderCaptcha" data-sentry-source-file="EntryForm.tsx" />;
  };
  return <>
			{errors.length ? errors.map((error, index) => <p className={styles.Error} key={index}>
							{error}
						</p>) : null}
			{!showChallengeRecaptcha ? renderForm() : null}
			{renderCaptcha()}
		</>;
};